import { useContext } from 'react';
import CurrentDepartmentContext from 'contexts/CurrentDepartmentContext';

const useCurrentDepartment = () => {
  const context = useContext(CurrentDepartmentContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useCurrentDepartment;

import Routes from 'routes';
import ThemeCustomization from 'themes';
import Notistack from 'components/third-party/Notistack';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { OrganizationProvider } from 'contexts/OrganizationContext';
import { AuthorizationProvider } from 'contexts/AuthorizationContext';
import { CapsuleProvider } from 'contexts/CapsuleContext';
import { CurrentDepartmentProvider } from 'contexts/CurrentDepartmentContext';
import { ActionCableProvider } from 'contexts/ActionCableContext';

const App = () => (
  <ThemeCustomization>
    <OrganizationProvider>
      <AuthProvider>
        <AuthorizationProvider>
          <CapsuleProvider>
            <CurrentDepartmentProvider>
              <ActionCableProvider>
                <Notistack>
                  <Routes />
                </Notistack>
              </ActionCableProvider>
            </CurrentDepartmentProvider>
          </CapsuleProvider>
        </AuthorizationProvider>
      </AuthProvider>
    </OrganizationProvider>
  </ThemeCustomization>
);

export default App;
